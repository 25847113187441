
import { PropertyMaintenanceSystemsResource, PropertyResource } from "@/resources";
import { Component, ProvideReactive, Vue} from "vue-property-decorator"
import PropertyMaintenanceItemsPanel from "@/components/properties/PropertyMaintenanceItemsPanel.vue";

@Component({components : {PropertyMaintenanceItemsPanel}})
export default class TestView extends Vue { 

  @ProvideReactive() propertyResource : PropertyResource | null = null
  propertyMaintenanceSystem : PropertyMaintenanceSystemsResource | null = null

  created() {
      let pms = new PropertyMaintenanceSystemsResource("3bf57309-c472-4ad4-8eb4-fd30e57b7347")
      pms.get().then(() => {
        this.propertyMaintenanceSystem = pms
        pms.property.getAssociation().then(p => {
          this.propertyResource = new PropertyResource(p.data().id)
        })
      })
  }
}
